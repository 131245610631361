import {
  Component,
  createSignal,
  createEffect,
  on,
} from "solid-js";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";
import { ExpressLineProps } from "../types";

export const CancelBackOrderConsent: Component<ExpressLineProps> = (props) => {
  const [choice, setChoice] = createSignal();
  createEffect(
    on(
      () => choice(),
      (choice) => {
        if (choice === "Cancel") {
          props.setter(
            "lines",
            props.type,
            props.index(),
            "CancelBackOrder",
            true
          );
          props.setter(
            "lines",
            props.type,
            props.index(),
            "BackOrder",
            undefined
          );
        } else if (choice === "Backorder") {
          props.setter(
            "lines",
            props.type,
            props.index(),
            "CancelBackOrder",
            undefined
          );
          props.setter("lines", props.type, props.index(), "BackOrder", true);
        }
      },
      { defer: true }
    )
  );

  return (
    <div class="flex flex-col gap-2 text-sm font-light">
      <p>
        This item has low stock but we'll try our best to fulfill it. If we are
        unable to, please choose an option:
      </p>

      <div class="flex items-center">
        <button
          aria-label="Place item on backorder"
          class=" w-5 aspect-square shrink-0 flex justify-center items-center self-center"
          classList={{
            "bg-white border": choice() !== "Backorder",
            "bg-roma-blue": choice() === "Backorder",
          }}
          onClick={() => {
            setChoice("Backorder");
          }}
        >
          <Icon path={check} class="text-white w-4" stroke-width={3} />
        </button>
        <p class="ml-4">Place item on backorder</p>
      </div>

      <div class="flex items-center">
        <button
          aria-label="Cancel item, do not place on backorder"
          class=" w-5 aspect-square shrink-0 flex justify-center items-center self-center"
          classList={{
            "bg-white border": choice() !== "Cancel",
            "bg-roma-blue": choice() === "Cancel",
          }}
          onClick={() => {
            setChoice("Cancel");
          }}
        >
          <Icon path={check} class="text-white w-4" stroke-width={3} />
        </button>
        <p class="ml-4">Cancel item, do not place on backorder</p>
      </div>
    </div>
  );
};
