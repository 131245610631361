import {
  FlowComponent,
  createSignal,
  createEffect,
  Show,
  For,
  Switch,
  Match,
} from "solid-js";
import { produce } from "solid-js/store";
import { Icon } from "solid-heroicons";
import { chevronDown, exclamationCircle } from "solid-heroicons/outline";
import { ExpressLineProps } from "../types";
import { ThresholdCheckout } from "~/utils/threshold";
import { ConsentCheckBox, CancelBackOrderConsent, CuttingOther } from "./";

export const Line: FlowComponent<ExpressLineProps> = (props) => {
  const currLine = () => props.store.lines[props.type][props.index()];
  const [isExpanded, setIsExpanded] = createSignal(false);

  createEffect(() => {
    if (!currLine().Consents.FreightConsentRequired) {
      props.setter(
        "lines",
        props.type,
        props.index(),
        "FreightAccepted",
        undefined
      );
    }
    if (currLine().Consents.CheckoutPermission === ThresholdCheckout.allow) {
      props.setter(
        produce((str) => {
          str.lines[props.type][props.index()].BackOrder = undefined;
          str.lines[props.type][props.index()].CancelBackOrder = undefined;
        })
      );
    }
  });

  return (
    <tbody
      class="divide-y divide-gray-200 bg-white align-top"
      classList={{
        "!bg-red-50": currLine().pricingError(),
      }}
    >
      {props.children}
      {/* BACKORDER CONSENTS */}
      <Switch>
        <Match
          when={
            currLine().Consents.CheckoutPermission ===
            ThresholdCheckout.optionalBackorder
          }
        >
          <tr>
            <td colspan="100%" class="bg-orange-50 px-6 py-2">
              <CancelBackOrderConsent {...props} />
            </td>
          </tr>
        </Match>
        <Match
          when={
            currLine().Consents.CheckoutPermission ===
            ThresholdCheckout.backorder
          }
        >
          <tr>
            <td colspan="100%" class="bg-orange-50 px-6 py-2">
              <ConsentCheckBox
                value={props.store.lines[props.type][props.index()].BackOrder}
                setter={(x) => {
                  props.setter(
                    "lines",
                    props.type,
                    props.index(),
                    "BackOrder",
                    x
                  );
                }}
              >
                In order to proceed with checkout, please acknowledge that this
                item will remain on backorder until stock is replenished.
              </ConsentCheckBox>
            </td>
          </tr>
        </Match>
      </Switch>
      {/* FREIGHT CHARGE CONSENT */}
      <Show when={currLine().Consents.FreightConsentRequired}>
        <tr>
          <td colspan="100%" class="bg-orange-50 px-6 py-2">
            <ConsentCheckBox
              value={
                props.store.lines[props.type][props.index()].FreightAccepted
              }
              setter={(x) => {
                props.setter(
                  "lines",
                  props.type,
                  props.index(),
                  "FreightAccepted",
                  x
                );
              }}
            >
              As a result of your selection, you agree to the additional LTL
              freight charges that may be incurred.
            </ConsentCheckBox>
          </td>
        </tr>
      </Show>
      {/* CUTTING INSTRUCTION OTHER */}
      <Show when={currLine().Consents.CuttingRequired}>
        <tr>
          <td colspan="100%">
            <CuttingOther {...props} />
          </td>
        </tr>
      </Show>
      {/* NOTICES */}
      <Show when={Object.keys(currLine().Notices).length > 0}>
        <tr>
          <td colspan="100%" class="px-6">
            <div class="w-full font-light text-sm">
              <For each={Object.values(currLine().Notices)}>
                {(val) => (
                  <div class="flex items-center py-1">
                    <Icon
                      path={exclamationCircle}
                      class="w-5 text-orange-500"
                    />
                    <span class="ml-4">{val as string}</span> <br />
                  </div>
                )}
              </For>
            </div>
          </td>
        </tr>
      </Show>
      {/* ERRORS */}
      <Show
        when={!!currLine().Errors && Object.keys(currLine().Errors!).length > 0}
      >
        <tr class="">
          <td colspan="100%" class="text-sm font-light px-6">
            <button
              aria-label={
                isExpanded()
                  ? "Hide Errors"
                  : `Show ${Object.keys(currLine().Errors!).length} Errors`
              }
              class="font-medium py-1 flex w-full text-gray-500"
              onClick={() => {
                setIsExpanded(!isExpanded());
              }}
            >
              <Icon
                path={chevronDown}
                class={`w-5  transition-transform transform ${
                  isExpanded() ? "flip-up" : ""
                }`}
              />
              <span class="ml-4 text-red-500">
                {isExpanded()
                  ? "Hide Errors"
                  : `Show ${Object.keys(currLine().Errors!).length} Errors`}
              </span>
            </button>
            <div
              class={`w-full ${
                !isExpanded() ? "max-h-0" : "max-h-[400px]"
              } overflow-hidden transition-all ease-linear duration-300`}
            >
              <ul class="text-red-500 pb-3 list-disc list-inside ">
                <For each={Object.entries(currLine().Errors!)}>
                  {([key, val]) => (
                    <li>
                      <span>{val}</span>
                    </li>
                  )}
                </For>
              </ul>
            </div>
          </td>
        </tr>
      </Show>
      {/* GENERAL PRICING ERROR */}
      <Show when={currLine().pricingError()}>
        <tr class="">
          <td
            colspan="100%"
            class="text-sm font-light px-6 py-2 text-red-600 bg-red-50"
          >
            A pricing error has occurred for this item. Please try again or
            remove this item before proceeding. We apologize for the
            inconvenience.
          </td>
        </tr>
      </Show>
      {/* DEBUGGING */}
      {/* <tr>
        <td colspan="100%" class="bg-orange-50 text-xs whitespace-pre">
          <pre>{JSON.stringify(currLine(), null, 4)}</pre>
        </td>
      </tr> */}
    </tbody>
  );
};
