import { Accessor, FlowComponent } from "solid-js";
import { Checkbox } from "~/components/inputs";

export const ConsentCheckBox: FlowComponent<{
  setter: (...args: any) => void;
  value?: boolean;
}> = (props) => {
  return (
    <div class="flex w-full">
      <Checkbox
        onChange={(checked: boolean) => props.setter(checked)}
        controlClass="!rounded-none !border border-gray-100 bg-white self-center"
      />
      <p class="ml-4 font-light text-sm">{props.children}</p>
    </div>
  );
};
