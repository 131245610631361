import { type Component, createEffect } from "solid-js";
import { SelectBox } from "~/components/inputs";
import { PT } from "~/utils/products";
import type { ExpressLineProps } from "../types";

export const Layer: Component<ExpressLineProps> = (props) => {
  createEffect(() => {
    if ([PT.JOIN, PT.CHOP, PT.RAIL].includes(props.type as PT)) {
      if (props.store.lines[props.type][props.index()].isStretcher) {
        props.setter("lines", props.type, props.index(), "Layer", "O");
      } else {
        props.setter("lines", props.type, props.index(), "Layer", "I");
      }
    }
  });

  return (
    <SelectBox
      options={[
        { label: "Inside", value: "I" },
        { label: "Outside", value: "O" },
      ]}
      name="layer"
      onChange={(option) => {
        props.setter(
          "lines",
          props.type,
          props.index(),
          "Layer",
          option.value as "I" | "O"
        );
      }}
      value={{ value: props.store.lines[props.type][props.index()].Layer }}
      placeholder="Layer"
      class={`w-[100px] rounded-md `}
      background={`${
        props.store.lines[props.type][props.index()].Errors?.Layer
          ? "bg-red-50"
          : "bg-white"
      }`}
    />
  );
};
