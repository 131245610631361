import { type Component } from "solid-js";
import { SelectBox } from "~/components/inputs";
import {  PT } from "~/utils/products";
import { galleryFrameSizes, photoFrameSizes } from "~/utils/builtFrames";
import type { ExpressLineProps } from "../types";

export const FrameSize: Component<ExpressLineProps> = (props) => {
  const options = () => {
    if (![PT.PHOTOFRAME, PT.GALLERYFRAME].includes(props.type as PT)) return [];
    const arr = props.type === PT.GALLERYFRAME ? [...galleryFrameSizes] : [...photoFrameSizes];
    return arr.map((item) => {
      return {
        label: (
          <div class="flex justify-start items-center">
            {item.width}
            <span class="text-xs mx-1">&#10005;</span>
            {item.height}
          </div>
        ),
        value: item.code,
      };
    });
  };

  return (
    <SelectBox
      options={options()}
      name="framesize"
      onChange={(option) => {
        props.setter("lines", props.type, props.index(), "Size", option.value as string);
      }}
      disabled={!!props.store.lines[props.type][props.index()].Errors?.SKU}
      placeholder="Select an option"
      class={`w-[150px] rounded-md ${
        props.store.lines[props.type][props.index()].Errors?.Size
          ? "!bg-red-50"
          : ""
      }`}
    />
  );
};
