import { ExpressStoreLine } from "../types";
import {
  LENGTH,
  BOX,
  CONTRACT,
  PT,
} from "~/utils/products";
import { stockThreshold, ThresholdCheckout } from "~/utils/threshold";

export const lineConsents = (obj: ExpressStoreLine) => {
  let result: {
    CheckoutPermission?: ThresholdCheckout;
    FreightConsentRequired?: boolean;
    CuttingRequired?: boolean;
  } = {};

  const stockLevel = () => {
    if (obj.plantDetails.latest?.plantList) {
      return obj.plantDetails.latest.stockLevels[
        obj.Plant === "defaultPlant" ? obj.plantDetails.latest.defaultPlant! : obj.Plant
      ];
    }
  };

  const checkoutPermission = () => {
    if (stockLevel() !== undefined && obj.productData.latest?.valid) {
      return stockThreshold(
        stockLevel()!,
        obj.productData.latest.Category,
        obj.productData.latest.Discontinued
      );
    }
  };

  if (stockLevel() !== undefined && checkoutPermission()) {
    result.CheckoutPermission = checkoutPermission()?.checkout; // Allow || Backorder || Optional Backorder || Block
  }

  if (
    [PT.CONTRACT, PT.BOX].includes(obj.Type) ||
    (obj.Type === LENGTH && obj.Cutting === "full")
  ) {
    result.FreightConsentRequired = true;
  }

  if (obj.Type === LENGTH && obj.CuttingOther === true) {
    result.CuttingRequired = true;
  } 

  return result;
};
