import { Show, on, Component, createSignal, createEffect } from "solid-js";
import { Select } from "@kobalte/core";
import { fracStringDictionary, decimalString } from "~/utils/fraction";
import { debounce } from "@solid-primitives/scheduled";

import "./sizeSelector.css";

const fracValues = Object.values(fracStringDictionary);

type SizeSelectorProps = {
  class?: string;
  placeholder: string;
  onChange: (integer: number | undefined) => void;
  inputClass?: string;
};

export const SizeSelector: Component<SizeSelectorProps> = (props) => {
  let inputRef: HTMLInputElement;
  const [value, setValue] = createSignal<number | undefined>();
  const [fraction, setFraction] = createSignal<number | undefined>();
  const [opened, setOpened] = createSignal();
  const close = debounce(() => setOpened(false), 250);
  const open = () => {
    setOpened(true);
    setTimeout(() => inputRef.focus());
  };
  createEffect(
    on(
      () => [value(), fraction()],
      () => props.onChange((value() || 0) + (fraction() || 0)),
      { defer: true }
    )
  );
  return (
    <div
      class="relative flex border border-gray-300 rounded-lg w-28"
      classList={{
        [`${props.class}`]: !!props.class,
      }}
    >
      <Show when={!opened()}>
        <Show when={value() || fraction()}>
          <div class="absolute flex gap-1 justify-center items-center pointer-events-none h-full w-full rounded-lg bg-white">
            <div>{value()}</div>
            <div class="fraction">{decimalString(fraction())}</div>
          </div>
        </Show>
        <input
          type="number"
          placeholder={props.placeholder}
          onClick={open}
          onFocus={open}
          class="text-sm px-3 py-2 text-gray-400 text-center w-full rounded-lg"
          classList={{ [`${props.inputClass}`]: !!props.inputClass }}
        />
      </Show>
      <Show when={opened()}>
        <input
          type="number"
          ref={(ref) => (inputRef = ref)}
          value={value()}
          placeholder={props.placeholder}
          class="w-full p-2 rounded-lg text-center outline-none placeholder:text-xs"
          onFocus={close.clear}
          onBlur={close}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              close();
              event.preventDefault();
            }
          }}
          onInput={(e: InputEvent) => {
            const raw = (e.currentTarget as HTMLInputElement).value;
            const value = !isNaN(Number(raw)) ? parseInt(raw) : undefined;
            if (raw === "") return;
            if (raw.toString().includes(".")) {
              const str = raw.toString().split(".");
              const dec = parseFloat(`.${str[1]}`);
              if (fracValues.includes(dec)) {
                setFraction(dec);
              }
            }
            setValue(value);
          }}
        />
        <Select.Root
          optionValue="value"
          // @ts-ignore
          optionTextValue="label"
          onFocus={close.clear}
          onBlur={close}
          // @ts-ignore
          disallowTypeAhead={true}
          onOpenChange={close}
          options={Object.entries(fracStringDictionary).map(
            ([label, value]) => ({
              value,
              label,
            })
          )}
          onChange={(option) => setFraction(option.value)}
          value={{ value: fraction() }}
          itemComponent={(state) => (
            <Select.Item
              onFocus={close.clear}
              onBlur={close}
              onClick={() => setOpened(false)}
              item={state.item}
              class="divide-y divide-gray-300"
            >
              <Select.ItemLabel class="flex gap-1 justify-center p-2 text-center cursor-pointer">
                <Show fallback="--" when={state.item.rawValue.label != ""}>
                  <span class="fraction">{state.item.rawValue.label}</span>
                </Show>
              </Select.ItemLabel>
            </Select.Item>
          )}
        >
          <Select.Trigger
            onFocus={close.clear}
            onBlur={close}
            class="relative border-l bg-gray-100/50 inline-flex items-center w-10 justify-between h-full"
          >
            <Show
              when={fraction()}
              fallback={
                <div class="flex justify-center items-center w-full">
                  <svg width="20px" height="20px" viewBox="0 0 15 15">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M.5 4a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5H.5Zm.5 6V5h1.075v2.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5h1.15v2.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5h1.15v1.5a.425.425 0 0 0 .85 0V5H14v5H1Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              }
            >
              <Select.Value class="w-full">
                {(state) => (
                  <>
                    <div class="gap-1 ounded flex justify-center items-center pointer-events-none w-full h-full">
                      <div class="fraction">{state.selectedOption().label}</div>
                    </div>
                  </>
                )}
              </Select.Value>
            </Show>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content class="select__content z-[15]">
              <Select.Listbox
                onFocus={close.clear}
                onBlur={close}
                // @ts-ignore
                autoFocus={false}
                class="select__listbox"
              />
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </Show>
    </div>
  );
};
