import { Component, createSignal, createEffect } from "solid-js";
import { SelectBox } from "~/components/inputs";
import { cuttingInstrOptions } from "../../order-page/product-types";
import type { ExpressLineProps } from "../types";

export const CuttingDropdown: Component<ExpressLineProps> = (props) => {
  const [choice, setChoice] = createSignal<string>();

  createEffect(() => {
    if (choice() === "other") {
      props.setter("lines", props.type, props.index(), "CuttingOther", true);
      props.setter("lines", props.type, props.index(), "Cutting", undefined);
    } else {
      props.setter(
        "lines",
        props.type,
        props.index(),
        "Cutting",
        choice() as string
      );
      props.setter(
        "lines",
        props.type,
        props.index(),
        "CuttingOther",
        undefined
      );
    }
  });

  return (
    <SelectBox
      options={cuttingInstrOptions}
      onChange={(option) => {
        setChoice(option.value as string);
      }}
      // @ts-expect-error
      optionTextValue="label"
      placeholder="Select an option"
      class={`w-[200px] rounded-md ${
        props.store.lines[props.type][props.index()].Errors?.Cutting
          ? "!bg-red-50"
          : ""
      }`}
    />
  );
};
