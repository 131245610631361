import { type Component, Show } from "solid-js";
import { SizeSelector } from "./SizeSelector";
import { SelectBox } from "~/components/inputs";
import type { ExpressLineProps } from "../types";
import { OrientationOption } from "~/services/roma-api/cart/types-productOrdering";

export const RailLength: Component<ExpressLineProps> = (props) => {
  return (
    // <ComboSizeSelector
    //   update={(x: number) => {
    //     props.setter("lines", props.type, props.index(), "Length", x);
    //   }}
    //   name="length"
    //   placeholder="Length"
    //   tabIndex="0"
    //   class={`rounded-md border-gray-300 ${props.store.lines[props.type][props.index()].Errors.Length ? "bg-red-50" : ""}`}
    //   // error={props.store.lines[props.type][props.index()].Errors.Length}
    // />
    <div>
      <SizeSelector
        onChange={(x) =>
          props.setter("lines", props.type, props.index(), "Length", x)
        }
        placeholder="Length"
        inputClass={`${
          props.store.lines[props.type][props.index()].Errors?.Length
            ? "bg-red-50"
            : ""
        }`}
      />
      <Show when={props.store.lines[props.type][props.index()].isStretcher}>
        <div class="mt-2 flex items-center">
          {/* <p class="text-xs basis-1/2 pl-2">ORIENTATION:</p> */}
          <SelectBox
            class="w-full"
            options={[
              { label: "Standard", value: "standard" },
              { label: "Gallery", value: "gallery" },
            ]}
            onChange={(option) => {
              props.setter(
                "lines",
                props.type,
                props.index(),
                "Orientation",
                option.value as OrientationOption
              );
            }}
            placeholder="Orientation"
            background={`${
              props.store.lines[props.type][props.index()].Errors?.Orientation
                ? "bg-red-50"
                : "bg-white"
            }`}
          />
        </div>
      </Show>
    </div>
  );
};
