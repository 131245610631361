import { Component } from "solid-js";
import { TextFieldInput } from "~/components/inputs";
import { debounce } from "@solid-primitives/scheduled";
import { ExpressLineProps } from "../types";

export const Quantity: Component<ExpressLineProps> = (props) => {
  const setQty = debounce((val: number) => {
    props.setter("lines", props.type, props.index(), "Quantity", val);
  }, 500);

  return (
    <TextFieldInput
      name="quantity"
      rootClass="w-[100px]"
      type="number"
      class={`${
        props.store.lines[props.type][props.index()]?.Errors?.Quantity
          ? "!bg-red-50"
          : ""
      }`}
      placeholder="Quantity"
      // TODO - did conversion toString() alter behaviour?
      defaultValue={props.store.lines[props.type][props.index()]?.Quantity?.toString() ||"0"}
      onChange={(val) => {
        setQty(+val);
      }}
    />
  );
};
