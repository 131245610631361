import { Component, Show } from "solid-js";
import { BaseLoader } from "~/components/utility";
import { ExpressLineProps } from "../types";
import { SelectBox } from "~/components/inputs";

export const PlantSelector: Component<ExpressLineProps> = (props) => {
  const line = () => props.store.lines[props.type][props.index()];
  return (
    <Show
      when={
        !props.store.lines[props.type][props.index()]?.plantDetails.loading &&
        line().plantDetails?.latest?.plantList
      }
      fallback={
        <Show
          when={line().plantDetails?.latest?.status === "error"}
          fallback={
            <div class="flex items-center justify-center py-2">
              <BaseLoader width={5} height={5} />
            </div>
          }
        >
          <div class="flex items-center justify-center py-2 border border-gray-300 rounded-md bg-red-50">
            Error
          </div>
        </Show>
      }
    >
      <SelectBox
        options={line()?.plantDetails?.latest?.plantList ?? []}
        name="plant"
        defaultValue={"defaultPlant"}
        onChange={(option) => {
          props.setter(
            "lines",
            props.type,
            props.index(),
            "Plant",
            option.value as string
          );
        }}
        disabled={line()?.plantDetails?.latest?.plantList?.length! <= 1}
        hideIndicator={line().plantDetails?.latest?.plantList?.length == 1}
        class={`w-[150px] rounded-md disabled:cursor-not-allowed ${
          props.store.lines[props.type][props.index()].Errors?.SKU
            ? "!bg-red-50"
            : ""
        }`}
        placeholder="Select an option"
      />
    </Show>
  );
};
