import { ExpressStoreLine } from "../types";

export const lineNotices = (obj: ExpressStoreLine) => {
  let result : {
    NonDefaultPlant?: string;
  } = {};

  if (obj.Plant !== "defaultPlant") {
    result.NonDefaultPlant = "Selecting this distribution center may result in additional shipping charges"
  }

  return result;
}