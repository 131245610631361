import { Component, Switch, Match } from "solid-js";
import { BaseLoader } from "~/components/utility";
import { ExpressLineProps } from "../types";

export const Amount: Component<ExpressLineProps> = (props) => {
  return (
    <div class="leading-6 py-2">
      <Switch fallback={<span>$0.00</span>}>
        <Match
          when={props.store?.lines[props.type][props.index()]?.pricing.loading}
        >
          <div class="flex items-center justify-end">
            <BaseLoader width={5} height={5} />
          </div>
        </Match>
        <Match
          when={props.store?.lines[props.type][props.index()]?.pricing?.latest}
        >
          <span>
            ${" "}
            {props.store.lines[props.type][
              props.index()
            ].pricing.latest?.Amount?.toFixed(2) || "0.00"}
          </span>
        </Match>
      </Switch>
    </div>
  );
};
