import { type Component, createMemo } from "solid-js";
import { SelectBox } from "~/components/inputs";
import {
  minWidthForSingleWedge,
  minWidthForDoubleWedge,
} from "~/utils/builtFrames";
import type { ExpressLineProps } from "../types";
import { WedgeOption } from "~/services/roma-api/cart/types-productOrdering";

export const WedgeLocks: Component<ExpressLineProps> = (props) => {
  const wedgeLockOptions = [
    { value: "none", label: "None" },
    { value: "single", label: "Single" },
    { value: "double", label: "Double" },
  ];

  const options = createMemo(() => {
    const w =
      props.store.lines[props.type][props.index()].productData?.latest?.Width ??
      0;

    if (
      props.store.lines[props.type][props.index()].productData?.latest
        ?.Floater ||
      w < minWidthForSingleWedge
    ) {
      return [{ value: "none", label: "N/A" }];
    } else if (w < minWidthForDoubleWedge) {
      return [...wedgeLockOptions.filter((item) => item.value !== "double")];
    } else if (w >= minWidthForDoubleWedge) {
      return [...wedgeLockOptions.filter((item) => item.value !== "single")];
    } else return [];
  });

  return (
    <SelectBox
      options={options()}
      name="wedgelocks"
      onChange={(option) => {
        props.setter(
          "lines",
          props.type,
          props.index(),
          "Wedge",
          option?.value as WedgeOption
        );
      }}
      disabled={options().length === 0}
      placeholder="Wedge"
      class={`w-[100px] rounded-md ${
        props.store.lines[props.type][props.index()].Errors?.Wedge
          ? "!bg-red-50"
          : ""
      }`}
    />
  );
};
