import { Component, Show } from "solid-js";
import { TextFieldInput } from "~/components/inputs";
import { ExpressLineProps } from "../types";
import { ProductImage } from "~/components/product";

export const SkuInput: Component<ExpressLineProps> = (props) => {
  return (
    <div class="flex items-center py-4 text-sm px-1 font-medium text-gray-900 sm:pl-6 grow-0 ">
      <div class="flex items-start gap-2 ">
        <Show
          when={
            props.store.lines[props.type][props.index()]?.productData?.latest
              ?.valid
          }
          fallback={
            <div class="w-10 aspect-square bg-roma-grey shrink-0 rounded-md" />
          }
        >
          <ProductImage
            class="w-10 h-10 bg-roma-grey p-1.5 rounded-md"
            src={`${import.meta.env.VITE_ROMA_CDN}/mouldings/${
              props.store.lines[props.type][props.index()]?.productData?.latest
                ?.SKU
            }-1.png?v=2`}
            width={40}
            height={40}
            quality={95}
            alt=""
          />
        </Show>
        <TextFieldInput
          name="sku"
          rootClass="w-[150px]"
          focusOnLoad
          class={`${
            props.store.lines[props.type][props.index()]?.Errors?.SKU
              ? "!bg-red-50"
              : ""
          }`}
          placeholder="Moulding"
          value={props.store.lines[props.type][props.index()].SearchVal}
          onInput={(e) => {
            props.setter(
              "lines",
              props.type,
              props.index(),
              "SearchVal",
              e.currentTarget.value,
            );
          }}
        />
      </div>
    </div>
  );
};
