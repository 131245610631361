import { Component, Show } from "solid-js";
import { SizeSelector } from "./";
import { SelectBox } from "~/components/inputs";
import type { ExpressLineProps } from "../types";
import { OrientationOption } from "~/services/roma-api/cart/types-productOrdering";

export const Dimensions: Component<ExpressLineProps> = (props) => {
  return (
    <>
      <div>
        <div class="flex">
          <SizeSelector
            placeholder="Width"
            class="border-r-transparent rounded-r-none rounded-md"
            onChange={(x) => {
              props.setter("lines", props.type, props.index(), "Width", x);
            }}
            inputClass={`${
              props.store.lines[props.type][props.index()].Errors?.Width
                ? "bg-red-50 rounded-r-none"
                : ""
            }`}
          />
          <SizeSelector
            placeholder="Height"
            class="rounded-l-none rounded-md"
            onChange={(x) => {
              props.setter("lines", props.type, props.index(), "Height", x);
            }}
            inputClass={`${
              props.store.lines[props.type][props.index()].Errors?.Height
                ? "bg-red-50 rounded-l-none"
                : ""
            }`}
          />
        </div>
        <Show when={props.store.lines[props.type][props.index()].isStretcher}>
          <div class="mt-2 flex items-center">
            {/* <p class="text-xs basis-1/2 pl-2">ORIENTATION:</p> */}
            <SelectBox
              class="w-full"
              options={[
                { label: "Standard", value: "standard" },
                { label: "Gallery", value: "gallery" },
              ]}
              onChange={(option) => {
                props.setter(
                  "lines",
                  props.type,
                  props.index(),
                  "Orientation",
                  option.value as OrientationOption
                );
              }}
              placeholder="Orientation"
              background={`${
                props.store.lines[props.type][props.index()].Errors?.Orientation
                  ? "bg-red-50"
                  : "bg-white"
              }`}
            />
          </div>
        </Show>
      </div>
    </>
  );
};
