import { Component } from "solid-js";
import { debounce } from "@solid-primitives/scheduled";
import { Icon } from "solid-heroicons";
import { pencilSquare } from "solid-heroicons/outline";
import { ExpressLineProps } from "../types";
import { TextFieldInput } from "~/components/inputs";

export const CuttingOther: Component<ExpressLineProps> = (props) => {
  const setVal = debounce((x: string | undefined) => {
    props.setter("lines", props.type, props.index(), "Cutting", x);
  }, 250);

  return (
    <div class="flex items-start px-6 py-1 text-gray-500 w-full text-sm">
      <Icon path={pencilSquare} class="w-5" />
      <div class="ml-4 w-full pb-2">
        <p class="mb-1">Custom Cutting Instructions</p>
        <TextFieldInput
          name="cuttingOther"
          focusOnLoad
          placeholder="Enter custom cutting instructions"
          class={`w-full font-light ${
            props.store.lines[props.type][props.index()]?.Errors?.Cutting
              ? "!bg-red-50"
              : ""
          }`}
          onChange={(val) => setVal(val)}
        />
      </div>
    </div>
  );
};
