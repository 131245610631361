import { type Component, Show } from "solid-js";
import { BaseLoader } from "~/components/utility";
import type { ExpressLineProps } from "../types";

export const BoxQty: Component<ExpressLineProps> = (props) => {
  return (
    <>
      <p class="py-2 leading-6 text-sm">
        <Show
          when={
            props.store?.lines[props.type][props.index()]?.productData?.loading
          }
          fallback={
            <span>
              {
                props.store?.lines[props.type][props.index()]?.productData
                  ?.latest?.BoxQty
              }{" "}
              ft
            </span>
          }
        >
          <div class="flex items-center justify-start">
            <BaseLoader width={5} height={5} />
          </div>
        </Show>
      </p>
    </>
  );
};
