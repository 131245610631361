import { type Component } from "solid-js";
import { TextFieldInput } from "~/components/inputs";
import { debounce } from "@solid-primitives/scheduled";
import type { ExpressLineProps } from "../types";

export const LinearFeet: Component<ExpressLineProps> = (props) => {

  const setLinearFt = debounce((val: number)=>{
    props.setter("lines", props.type, props.index(), "Length", val)
  }, 500)

  return (
    <TextFieldInput
      name="linearFeet"
      rootClass="w-[100px]"
      type="number"
      class={`${
        props.store.lines[props.type][props.index()]?.Errors?.LinearFt
          ? "!bg-red-50"
          : ""
      }`}
      placeholder="Linear Ft"
      // TODO - check if conversion toString() or "0" changes behaviour
      defaultValue={props.store.lines[props.type][props.index()].Length?.toString() || "0"}
      onChange={(val:string)=>{
        setLinearFt(+val)
      }}
    />
  );
};
