import { type Component, createEffect } from "solid-js";
import { SelectBox } from "~/components/inputs";
import type { ExpressLineProps } from "../types";
import { AllowanceOption } from "~/services/roma-api/cart/types-productOrdering";

export const allowanceOptionsAbbr = [
  { value: "1/8", label: '1/8"' },
  { value: "1/16", label: '1/16"' },
  { value: "exact", label: "Exact" },
  { value: "3/16", label: '3/16"' },
  { value: "sightSize", label: "Sight Size" },
];

export const Allowance: Component<ExpressLineProps> = (props) => {
  createEffect(() => {
    // Default value request from Tanya for Allowances:
    // default of "exact" for floater mouldings
    if (props.store.lines[props.type][props.index()].isFloater) {
      props.setter("lines", props.type, props.index(), "Allowance", "exact");
    }
    // default of "1/8" is being set when the line in created in express.tsx
  });

  return (
    <SelectBox
      options={allowanceOptionsAbbr}
      name="allowance"
      onChange={(option) => {
        props.setter(
          "lines",
          props.type,
          props.index(),
          "Allowance",
          option.value as AllowanceOption
        );
      }}
      value={{ value: props.store.lines[props.type][props.index()].Allowance }}
      disabled={props.store.lines[props.type][props.index()].Layer === "O"}
      placeholder="Allowance"
      class={`w-[125px] rounded-md ${
        props.store.lines[props.type][props.index()].Errors?.Allowance
          ? "!bg-red-50"
          : ""
      }`}
    />
  );
};
